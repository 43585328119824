<template>
    <div>
        <v-layout row wrap text-xs-center>
            <v-flex xs12>
                <h2 class="font-weight-bold text-xs-left"><u>Campaign: {{campaign.name}}</u></h2>
            </v-flex>
            <v-flex xs6 class="text-xs-left" v-show="campaign.status == 'Ongoing'">
                <v-btn @click.stop="serviceUnavailable()" @click="interruptDialog = false" small class="elevation-6 white--text ma-0 text-xs-center" round color="secondary lighten-3" >
                    <v-icon left small class="fas fa-cancel" > </v-icon>
                    Interrupt
                </v-btn>
            </v-flex>
            <v-dialog width="500px" v-model="interruptDialog"> 
                <v-card> 
                    <v-card-title class="headline primary darken-2 white--text" primary-title>Confirm Campaign Interruption</v-card-title> 
                    <input type="hidden" v-model="payment_ref" ref="payment_ref" id="payment_ref">
                    <v-card-text class="pa-4">
                        <p>Inorder to interrupt this campaign, you will pay a <b style="color: red"> 5% dispurtion fee</b> of the saved amount in this campaign</p>
                        <p><b>Note:</b> Once this interuption is confirmed by you, it takes <b>24-48hrs</b> to be approved</p>
                    </v-card-text>

                    <v-card-actions> 
                        <v-btn color="red" class="white--text" @click="setInterruptCampaign()" > Interrupt </v-btn> 
                        <v-spacer></v-spacer> 
                        <v-btn color="teal" flat @click="interruptDialog = false" > Cancel </v-btn> 
                    </v-card-actions> 
                </v-card> 
            </v-dialog> 

            <v-flex xs6 class=" text-xs-right" v-show="campaign.status == 'Ongoing'">
                <v-btn small class="animate__animated animate__flash animate__delay-1s elevation-6 white--text ma-0 text-xs-center" round color="teal darken-2 " :to="'/savings/campaigns/save/'+campaign.id" >
                    <v-icon left small class="fas fa-piggy-bank" > </v-icon>
                    Save Now
                </v-btn>
            </v-flex>
        </v-layout>

        <v-card class="primary darken-2 mt-1" >
            <v-card-text v-if="campaign.target_amount && campaign.target_date" class="pa-2" style="font-size: 12px; ">
                <span class="white--text text-xs-left subheading"><b>Amount Saved:</b> 
                    <span class="pa-0 ma-1 dark--text text-xs-left body-2"> {{campaign.saved_amount}} FCFA in {{campaign.passed_days}} days</span>
                </span>
            </v-card-text>
            <v-card-text v-else-if="campaign.target_amount" class="pa-2" style="font-size: 12px; ">
                <span class="white--text text-xs-left subheading"><b>Amount Saved:</b> 
                    <span class="pa-0 ma-1 dark--text text-xs-left body-2"> {{campaign.saved_amount}} FCFA </span>
                </span>
            </v-card-text>
            <v-card-text v-else-if="campaign.target_date" class="pa-2" style="font-size: 12px; ">
                <span class="white--text text-xs-left subheading"><b>Amount Saved:</b> 
                    <span class="pa-0 ma-1 dark--text text-xs-left body-2"> {{campaign.saved_amount}} FCFA </span>
                </span>
            </v-card-text>
        </v-card>
        
        <v-card class="white elevation-6 "  >
            <v-card-text class="body-2 pa-2" style="font-size: 12px; padding: 5px">
                <span><b>Creation Date:</b> <span class="caption"> {{campaign.get_date_str}}</span></span>
                <v-spacer></v-spacer>
                <div v-if="campaign.target_amount && campaign.target_date" >
                    <span><b>Objective:</b> <span class="caption"> Reach {{campaign.target_amount}} F CFA By the {{campaign.target_date}} </span> </span>
                    <v-spacer></v-spacer>
                    <span><b>Remaining:</b> <span class="caption"> {{campaign.remaining_days}} days left</span></span>
                </div>
                <div v-else-if="campaign.target_amount" >
                    <span><b>Objective:</b> <span class="caption"> Reach {{campaign.target_amount}} F CFA </span> </span>
                    <v-spacer></v-spacer>
                    <span><b>Remaining:</b> <span class="caption"> {{campaign.remaining_days}} F CFA</span></span>
                </div>
                <div v-else-if="campaign.target_date" >
                    <span><b>Objective:</b> <span class="caption"> On the {{campaign.target_date}} </span> </span>
                    <v-spacer></v-spacer>
                    <span><b>Remaining:</b> <span class="caption"> {{campaign.remaining_days}} days left</span></span>
                </div>
                <v-spacer></v-spacer>
                <span><b>Percentage Complete: </b> <span class="caption"> {{campaign.percentage_completed}}</span></span>
                <v-spacer></v-spacer>
                <span><b>Description: </b> <span class="caption"> {{campaign.description}}</span></span>
            </v-card-text>
        </v-card>

        <h2 v-if="campaignRequestedTransactions.length !== 0" class="mt-3 dark--text font-weight-bold text-xs-left"><u>Requested Transactions</u></h2>
        <div v-if="campaignRequestedTransactions.length !== 0" class="white elevation-6 pa-2" style="max-height: 50px; overflow: auto; border-radius: 7px"> 
            <div color="white" style="" v-for="item in campaignRequestedTransactions" :key="item.id">
                <v-card-text class="pa-1" style="font-size: 12px;">
                    <v-layout row wrap v-if="!item.cancelled || item.withdrawal || (!item.withdrawal && !item.confirmed)">
                        <v-flex xs8 text-xs-left>
                            <div v-if="item.withdrawal" > <!-- this will be an interrupt-->
                                <span class="red--text font-weight-bold">Interrupt:</span><span> {{item.amount}} FCFA</span>
                            </div>
                            <div v-else>
                                <span class="dark--text font-weight-bold">Deposit:</span><span> {{item.amount}} FCFA</span>
                            </div>
                            <div v-if="!item.confirmed && !item.cancelled" xs8 text-xs-left>
                                <span class="font-weight-bold ">Status:</span><span class="primary--text font-weight-bold "> Pending </span>
                            </div>
                            <div v-if="item.confirmed" xs8 text-xs-left>
                                <span class="font-weight-bold ">Status:</span><span class="success--text font-weight-bold "> Confirmed </span>
                            </div>
                            <!-- <div v-if="item.cancelled" xs8 text-xs-left>
                                <span class="font-weight-bold ">Status:</span><span class="red--text font-weight-bold "> Cancelled </span>
                            </div> -->
                        </v-flex>
                        <v-flex xs4 text-xs-right>
                            <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_date_str}}</span>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            <v-divider></v-divider>
            </div>
        </div>

        <h2 class="mt-3 dark--text font-weight-bold text-xs-left"><u>Validated Transactions</u></h2>
        <div class="white elevation-6 pa-2" style="max-height: 300px; overflow: auto; border-radius: 7px"> 
            <div color="white" style="" v-for="item in campaignTransactions" :key="item.id">
                <v-card-text class="pa-1" style="font-size: 12px;">
                    <v-layout row wrap>
                        <v-flex xs6 text-xs-left>
                            <div v-if="item.withdrawal" >
                                <span class="dark--text font-weight-bold">Tranfered:</span><span> {{item.amount}} FCFA</span>
                            </div>
                            <div v-else>
                                <span class="dark--text font-weight-bold">Deposit:</span><span> {{item.amount}} FCFA</span>
                            </div>
                        </v-flex>
                        <v-flex xs6 text-xs-right>
                            <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_day_month_year_str}}</span>
                        </v-flex>
                        <v-flex xs8 text-xs-left>
                            <span class="dark--text font-weight-bold ">By:</span><span> {{item.top_up_admin_username}} - {{item.top_up_admin_phone_number}}</span>
                        </v-flex>
                        <v-flex xs4 text-xs-right>
                            <span class="dark--text font-weight-bold ">at</span><span> {{item.transaction_time}}</span>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            <v-divider></v-divider>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import 'animate.css'

    export default {
        data() {
            return {
                campaign: [],
                campaignTransactions: [],
                campaignRequestedTransactions: [],
                interruptDialog: false,
                payment_ref: '',
                interrupt_fees: '',
                blink: true,
            }
        },
        
        mounted(){
            // executes these after the page has been mounted
            this.getCampaignDetail();
            this.getCampaignTransactions();
            this.getCampaignRequestedTransactions();
            document.title = "PettyCash | Campaign Detail Info."
            this.$store.commit('setCloseURL', "/savings/campaigns/active")
        },
        
        methods:{
            serviceUnavailable(){
                this.$store.commit('setSnackBarMessage', "This is currently unavailable.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            },
            async getCampaignInterruptPaymentRef(){
                this.$store.commit('setIsLoading', true)
                
                const campaign_id = this.$route.params.campaign_id  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/savings/create/user/interrupt/campaign/payment_ref/'+campaign_id+'/')
                    .then(response => {
                        this.payment_ref = response.data['payment_ref']
                        this.interrupt_fees = response.data['interrupt_fees']
                        if (!response.data["status"]){
                            this.interruptDialog = false
                            this.$store.commit('setSnackBarMessage', "Interrupt Request Already Exist, The request is being processed.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async setInterruptCampaign(){
                this.$store.commit('setIsLoading', true)
                
                const campaign_id = this.$route.params.campaign_id  // get campaign category from url and send request to server
                let formData = new FormData()
                formData.append('payment_ref', this.payment_ref)
                await axios
                    .post('/api/v1/savings/interrupt/user/campaign/'+campaign_id+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        this.interruptDialog = false
                        this.$store.commit('setSnackBarMessage', response.data[0].text)
                        this.$store.commit('setSnackBarColor', response.data[0].color)
                        this.$store.commit('activateSnackBar', true)
                        var navigation = this.$router
                        var store = this.$store  // timers are noted for this
                        if (response.data[0].valid){
                            setTimeout(function(){
                                navigation.go()  // redirect back to campaign
                                store.commit('setSnackBarTimeOut', 3000)  // set time out for snack bar to 4 seconds and remember too reset after 
                            }, 3000)
                        }
                    })
                    .catch(error => {
                        this.interruptDialog = false
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getCampaignDetail(){
                this.$store.commit('setIsLoading', true)
                
                const campaign_id = this.$route.params.campaign_id  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/savings/get/user/campaign/detail/'+campaign_id+'/')
                    .then(response => {
                        this.campaign = response.data  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            async getCampaignTransactions(){
                this.$store.commit('setIsLoading', true)
                
                const campaign_id = this.$route.params.campaign_id  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/savings/get/user/campaign/'+campaign_id+'/transactions/')
                    .then(response => {
                        this.campaignTransactions = response.data  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            async getCampaignRequestedTransactions(){
                this.$store.commit('setIsLoading', true)
                
                const campaign_id = this.$route.params.campaign_id  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/savings/get/user/campaign/'+campaign_id+'/requested_transactions/')
                    .then(response => {
                        this.campaignRequestedTransactions = response.data  // get the data and fill into campaigns
                        console.log(this.campaignRequestedTransactions)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
        },

    }
</script>
